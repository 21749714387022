/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages 

                $(".close").click(function() {
                    location.reload();
                });

                setTimeout(function() {
                    $('body, html').scrollTop(0);
                    $('.load-overlay').velocity('fadeOut');
                }, 100);

                $('.liquid').imgLiquid();

                $('#scrollDown').click(function() {
                    $('body, html').velocity('scroll', { duration: 1000, offset: $('.home-header').outerHeight() });
                });

                $('.locaties .locatie').click(function() {
                    var slug = $(this).attr('data-slug');

                    $('input[name="city"]').val(slug);
                    $('.locaties .locatie').removeClass('selected');
                    $(this).addClass('selected');
                    $('#times').html('');
                     $('html, body').velocity("scroll", { duration: 1500, easing: "ease", offset: $('.step.step_2').offset().top });
                });

                $('.type .option').click(function() {
                    var slug = $(this).attr('data-slug');

                    $('input[name="type"]').val(slug);
                    $('.type .option').removeClass('selected');
                    $(this).addClass('selected');

                    if (slug == 'boat') {
                        console.log(slug);
                        $('.wrap_boat').show();
                        $('.wrap_plane').hide();
                    } else {
                        $('.wrap_boat').hide();
                        $('.wrap_plane').show();
                    }

                    calc_price();
                });

                function calc_price() {
                    var send_type = $('input[name="type"]').val();
                    var destinations = $('select[name="destinations"]').val();
                    var boat_weight = $('input[name="boat_weight"]').val();
                    var boat_aantal = $('input[name="boat_aantal"]').val();
                    var plane_weight = $('input[name="plane_weight"]').val();
                    var plane_count = $('input[name="plane_count"]').val();
                    var post_url = $('.home-header').attr('data-url');

                    $.ajax({
                        type: "POST",
                        url: post_url + '/ajax/price.php',
                        data: {
                            send_type: send_type,
                            destinations: destinations,
                            boat_weight: boat_weight,
                            boat_aantal: boat_aantal,
                            plane_weight: plane_weight,
                            plane_count: plane_count
                        },
                        success: function(val) {
                            console.log(val);
                            if (val !== 'false') {
                                $('.price').html('&euro;' + parseFloat(Math.round((parseFloat(val)) * 100) / 100).toFixed(2).replace('.00', '.-') + ' <span>incl. btw</span>');
                                $('.price').show();

                                $('input[name="price"]').val(parseFloat(Math.round((parseFloat(val)) * 100) / 100).toFixed(2));

                            } else {
                                $('.price').hide();
                            }
                        }
                    });
                }

                $(document).on('change', '.shipping', function() {
                    calc_price();
                });

                $(document).on('click', '.packages .pack', function() {
                    var slug = $(this).attr('data-slug');

                    $('input[name="boat_weight"]').val(slug);
                    $('.packages .pack').removeClass('selected');
                    $(this).addClass('selected');

                    calc_price();
                });

                function get_times() {
                    $('#times').html('<div class="loader"></div>');
                    var date = $('.date.selected').attr('data-date');
                    var day_num = $('.date.selected').attr('data-daynum');
                    var post_url = $('.home-header').attr('data-url');
                    var city = $('input[name="city"]').val();

                    $('input[name="date"]').val(date);

                    $.ajax({
                        type: "POST",
                        url: post_url + '/ajax/times.php',
                        data: {
                            date: date,
                            day_num: day_num,
                            city: city
                        },
                        success: function(val) {
                            if (val.length > 0) {
                                $('#times').html(val);
                                $('.dates .overlay').velocity('fadeOut');

                            } else {
                                alert('Er is iets fout gegaan');
                            }
                        }
                    });
                }

                $(document).on('click', '#times .time', function() {
                    if ($(this).hasClass('closed') == false) {
                        $('#times .time').removeClass('selected');
                        $(this).addClass('selected');

                        $('input[name="time_block_raw"]').val($(this).attr('data-time'));
                        $('input[name="time_block"]').val($(this).text());
                    }
                });

                $(document).on('click', '.dates .date', function() {
                    $('.dates .date').removeClass('selected');
                    $(this).addClass('selected');

                    get_times();
                });

                function validation() {
                    var city = $('input[name="city"]').val();
                    var send_type = $('input[name="type"]').val();
                    var destinations = $('select[name="destinations"]').val();
                    var boat_weight = $('input[name="boat_weight"]').val();
                    var boat_aantal = $('input[name="boat_aantal"]').val();
                    var plane_weight = $('input[name="plane_weight"]').val();
                    var plane_count = $('input[name="plane_count"]').val();
                    var name = $('input[name="name"]').val();
                    var phone = $('input[name="phone"]').val();
                    var email = $('input[name="email"]').val();
                    var adres = $('input[name="adres"]').val();
                    var postal = $('input[name="postal"]').val();
                    var message = '';

                    if (city.length === 0) {
                        message = 'Kies de omgeving waar u woont.';
                    } else if (send_type.length === 0) {
                        message = 'Kies een verzendmethode.';
                    } else if (destinations.length === 0) {
                        message = 'Kies een bestemming.';
                    } else if (boat_weight.length === 0 && send_type === 'boat') {
                        message = 'Kies het gewicht van uw pakket.';
                    } else if (boat_aantal === 0 && send_type === 'boat') {
                        message = 'Kies het aantal pakketten wat u wilt verzenden.';
                    } else if (plane_weight.length === 0 && send_type === 'plane') {
                        message = 'Vul het gewicht van uw pakket in.';
                    } else if (name.length === 0) {
                        message = 'Vul uw naam in.';
                    } else if (adres.length === 0) {
                        message = 'Vul uw straat + huisnummer in.';
                    } else if (phone.length === 0) {
                        message = 'Vul uw telefoonnummer in.';
                    } else if (email.length === 0) {
                        message = 'Vul uw emailadres in.';
                    } else if (postal.length === 0) {
                        message = 'Vul uw postcode in.';
                    } else {
                        message = '';
                    }

                    return message;
                }

                $('.get_dates').click(function() {
                    var message = validation();

                    if (message.length > 0) {
                        $('.errors .error').html(message);
                        $('.errors .error').css('opacity', 1);

                        $('html, body').velocity("scroll", { duration: 1500, easing: "ease", offset: $('.home-header').outerHeight() });

                    } else {
                        $('.errors .error').html('');
                        $('.errors .error').css('opacity', 0);
                        $('html, body').velocity("scroll", { duration: 1500, easing: "ease", offset: $('.step.step_3').offset().top });
                        get_times();
                    }
                });

                function send_order() {
                    var city = $('input[name="city"]').val();
                    var send_type = $('input[name="type"]').val();
                    var destinations = $('select[name="destinations"]').val();
                    var boat_weight = $('input[name="boat_weight"]').val();
                    var boat_aantal = $('input[name="boat_aantal"]').val();
                    var plane_weight = $('input[name="plane_weight"]').val();
                    var plane_count = $('input[name="plane_count"]').val();
                    var name = $('input[name="name"]').val();
                    var phone = $('input[name="phone"]').val();
                    var email = $('input[name="email"]').val();
                    var price = $('input[name="price"]').val();
                    var adres = $('input[name="adres"]').val();
                    var postal = $('input[name="postal"]').val();
                    var time_block_raw = $('input[name="time_block_raw"]').val();
                    var time_block = $('input[name="time_block"]').val();
                    var date = $('input[name="date"]').val();
                    var post_url = $('.home-header').attr('data-url');

                    if (date.length === 0) {
                        message = 'Kies een datum.';
                    } else if (time_block.length === 0) {
                        message = 'Kies een tijdsblok.';
                    } else {
                        message = '';
                    }

                    if (message.length > 0) {
                        $('.errors .error').html(message);
                        $('.errors .error').css('opacity', 1);

                        $('html, body').velocity("scroll", { duration: 1500, easing: "ease", offset: $('.home-header').outerHeight() });

                    } else {
                        $.ajax({
                            type: "POST",
                            url: post_url + '/ajax/send_order.php',
                            data: {
                                city: city,
                                send_type: send_type,
                                destinations: destinations,
                                boat_weight: boat_weight,
                                boat_aantal: boat_aantal,
                                plane_weight: plane_weight,
                                plane_count: plane_count,
                                name: name,
                                phone: phone,
                                email: email,
                                price: price,
                                adres: adres,
                                postal: postal,
                                time_block_raw: time_block_raw,
                                time_block: time_block,
                                date: date
                            },
                            success: function(val) {
                                if (val == 'false' || val == 'email') {
                                    $('.errors .error').html('Uw email is onjuist.');
                                    $('.errors .error').css('opacity', 1);

                                    $('html, body').velocity("scroll", { duration: 1500, easing: "ease", offset: $('.home-header').outerHeight() });

                                } else {
                                    $('.overlay_modal').velocity('fadeIn');
                                    $('#send_order').remove();
                                }
                            }
                        });
                    }
                }

                $('#send_order').click(function() {
                    var message = validation();

                    if (message.length > 0) {
                        $('.errors .error').html(message);
                        $('.errors .error').css('opacity', 1);

                        $('html, body').velocity("scroll", { duration: 1500, easing: "ease", offset: $('.home-header').outerHeight() });

                    } else {
                        send_order();
                    }
                });

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
